export default [
  {
    src: "/beceptum.png",
    alt: "Beceptum International Logo",
    url: "https://www.beceptum.com/",
    people: [
      {
        src: "/marc_zinkel.jpg",
        name: "Marc Zinkel",
        role: "Senior Partner & Managing Director",
      },
      {
        src: "/tobias_zech.jpg",
        name: "Tobias Zech",
        role: "Senior Partner & Managing Director",
      },
      {
        src: "/olaf_sass.jpg",
        name: "Olaf Sass",
        role: "Senior Manager",
      },
    ],
  },
  {
    src: "/isg.png",
    alt: "isg Logo",
    url: "https://isg-one.com/DE",
    people: [
      {
        src: "/christian_decker.jpg",
        name: "Christian Decker",
        role: "Partner",
      },
      {
        src: "/barbara_florschuetz.jpg",
        name: "Barbara Florschütz",
        role: "Partner & Managing Director",
      },
      {
        src: "/johanna_von_geyr.jpg",
        name: "Johanna von Geyr",
        role: "Partner",
      },
    ],
  },
  {
    src: "/knooing.png",
    alt: "knooing Logo",
    url: "https://knooing.de/",
    people: [
      {
        src: "/carsten_hochschon.jpg",
        name: "Carsten Hochschon",
        role: "Gründer & CEO",
      },
      {
        src: "/philipp_pietsch.jpg",
        name: "Philipp Pietsch",
        role: "Products & Business",
      },
      {
        src: "/selina_lena_wohlfahrt.jpg",
        name: "Selina-Lena Wohlfahrt",
        role: "Marketing & Testing",
      },
    ],
  },
  {
    src: "/distressedLadies.png",
    alt: "Distressed Ladies Logo",
    url: "http://distressed-ladies.com/de/",
    people: [
      {
        src: "/katharina_gerdes.jpg",
        name: "Katharina Gerdes",
        role:
          "Vorstandsmitglied & Rechtsanwältin" /* "Projektverantwortliches Vorstandsmitglied, Rechtsanwältin, Partnerin BRL BOEGE ROHDE LUEBBEHUESEN" */,
      },
      {
        src: "/sylwia_bea.jpg",
        name: "Dr. Sylwia Maria Bea",
        role:
          "Vorstandsmitglied & Rechtsanwältin" /*"Projektverantwortliches Vorstandsmitglied, Rechtsanwältin, Local Partner WHITE & CASE LLP"*/,
      },
      {
        src: "/jasmin_urlaub.jpg",
        name: "Dr. Jasmin Urlaub",
        role:
          "Vorstandsmitglied & Rechtsanwältin" /*Projektverantwortliches Vorstandsmitglied, Fachanwältin für Insolvenzrecht, Partnerin der MENOLD BEZLER*/,
      },
      {
        src: "/gesa_pantaleon.jpg",
        name: "Dr. Gesa Pantaleon gen. Stemberg",
        role: "Vorstandsmitglied & Rechtsanwältin",
      },
    ],
  },
  {
    src: "/acronis.png",
    alt: "Acronis Logo",
    url: "https://www.acronis.com/de-de/",
    people: [
      {
        src: "/heiko_von_soosten.jpg",
        name: "Heiko von Soosten",
        role: "General Manager DACH",
      },
      {
        src: "/alex_fuerst.jpg",
        name: "Alex Fuerst",
        role: "Vice President Enterprise",
      },
    ],
  },
  {
    src: "/interone.png",
    alt: "interone Logo",
    url: "https://interone.de/",
    people: [
      {
        src: "/boris_terwey.jpg",
        name: "Boris Terwey",
        role: "CEO",
      },
      {
        src: "/franzisca_tardy.jpg",
        name: "Franzisca Tardy",
        role: "Business Director",
      },
      {
        src: "/sebastian_erhardt.jpg",
        name: "Sebastian Erhardt",
        role: "Technical Director",
      },
    ],
  },
  {
    src: "/huebner.png",
    alt: "Hübner Management GmbH Logo",
    url: "mailto:britta.huebner@huebner-interim.de",
    people: [
      {
        src: "/britta_huebner.jpg",
        name: "Britta Hübner",
        role: "Geschäftsführende Gesellschafterin",
      },
    ],
  },
  {
    src: "/rs_medienberatung.png",
    alt: "rs medienberatung Logo",
    url: "https://rs-medienberatung.de/",
    people: [
      {
        src: "/ralf_schneider.jpg",
        name: "Dr. Ralf Schneider",
        role: "Inhaber rs medienberatung",
      },
    ],
  },
  /* {
    src: "/etl.png",
    alt: "etl",
    url: "https://www.etl.de/",
  }, */
]
