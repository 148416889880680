export default [
  {
    src: "/innovation_for_now.png",
    alt: "Innovation for Now",
    url: "https://innovationfornow.de/",
  },
  {
    src: "/mia_gehn_online.png",
    alt: "Mia gehn online Logo",
    url: "https://miagehn.online/",
  },
  {
    src: "/mask_radar.png",
    alt: "Mask Radar Logo",
    url: "https://www.maskradar.de/",
  },
  {
    src: "/startups_against_corona.png",
    alt: "Startups against Corona Logo",
    url: "https://startupsagainstcorona.com/",
  },
]
