import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import StyledHero from "../components/styledHero"
import SimpleHero from "../components/simpleHero"
import AboutUs from "../components/about/aboutUs"
import Motivation from "../components/about/motivation"
import Partners from "../components/about/partners"
import Ambassadors from "../components/about/ambassadors"
import Initiatives from "../components/about/initiatives"
import SEO from "../components/seo"

const About = ({ data, location }) => {
  return (
    <Layout location={location.pathname}>
      <SEO title="Über Uns" />
      <StyledHero img={data.aboutBcg.childImageSharp.fluid} />
      <AboutUs />
      <SimpleHero img={data.motivationBcg.childImageSharp.fluid} />
      <Motivation />
      <Partners />
      <Ambassadors />
      <Initiatives />
    </Layout>
  )
}

export const query = graphql`
  query {
    aboutBcg: file(relativePath: { eq: "aboutBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    motivationBcg: file(relativePath: { eq: "defaultBcg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default About
