import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import Title from "../title"
import styles from "../../css/text.module.css"

const Motivation = () => {
  return (
    <section className={styles.text}>
      <Title title="Unsere" subtitle="Motivation" />
      <div className={styles.center}>
        <article>
          <p>
            Zahlreiche Firmen, Unternehmer und Selbständige haben uns in den
            letzten Tagen angesprochen und wollen ihre Dienstleistungen und
            Expertise pro bono der öffentlichen Hand und notleidenden
            Unternehmen zur Verfügung stellen, verfügen aber nicht über die
            notwendigen Zugänge und Kontakte. Gleichzeitig haben wir in unserem
            internationalen Netzwerk Produkte und Technologien identifiziert,
            die einen signifikanten Beitrag zur Krisenbewältigung leisten können
            und sie an die relevanten Ministerien und öffentlichen Stellen
            bereits herangetragen. Dabei sind wir in unserem Netzwerk auf
            ähnliche Erfahrungen gestoßen, woraus sich eine Gruppe von
            Unternehmen und Persönlichkeiten spontan zu einer
            Gemeinschaftsinitiative zusammengetan hat. Wie so viele sind auch
            wir alle davon überzeugt, dass wir nur solidarisch und in einem
            Schulterschluss die Krise, vor allem die wirtschaftliche Krise, in
            der nächsten Zeit meistern können und haben uns Gedanken gemacht, in
            welcher Form wir einen Beitrag leisten können.
          </p>
          <p>
            Unsere Idee ist nun, eine Plattform auf die Beine zu stellen und zu
            koordinieren, die Hilfsangebote aus der Wirtschaft mit der Nachfrage
            der öffentlichen Hand und stark gefährdeter Unternehmen
            unentgeltlich, schnell und unbürokratisch zusammenbringt und
            ergänzende finanzielle Mittel zur Stützung der Wirtschaft
            zusammenträgt. Als Zeitrahmen haben wir zunächst ab sofort bis 31.
            Juli 2020 festgelegt.
          </p>
          <p>
            Die Plattform lebt von Angebot und Nachfrage. Und sie lebt von
            Unterstützern. Wenn Sie also etwas anbieten wollen, dringenden
            Bedarf an Projekt(zu)arbeit oder Servicedienstleistungen haben oder
            die Plattform mit Ihrer Arbeitskraft, finanziell oder als
            Botschafter unterstützen möchten –{" "}
            <AniLink fade to="/join">
              machen Sie mit!
            </AniLink>
          </p>
          <p>
            Die Plattforminitiative läuft unter dem entsprechenden Hashtag
            #vscovidunited.
          </p>
          <p>
            Wenn wir zusammenhalten, kommen wir besser durch die Krise und -
            noch wichtiger – besser und stärker aus ihr hervor. Wir als
            Plattformpartner wollen einen Beitrag dazu leisten und freuen uns
            auf ehrliche und ernst gemeinte Angebote und Anfragen, die wir nach
            besten Kräften unterstützen wollen und können. Wir freuen uns über
            Anmeldungen, Rückmeldungen und Feedback. Und natürlich beantworten
            wir gerne alle Fragen. Helfen Sie uns, die Plattform zu unser aller
            Nutzen und Wohl erfolgreich zu machen. Und bleiben Sie gesund dabei!
          </p>
        </article>
      </div>
    </section>
  )
}

export default Motivation
