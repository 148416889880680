import React from "react"
import Title from "../title"
import styles from "../../css/partners.module.css"
import ambassadorsSingle from "../../constants/ambassadorsSingle"
import ambassadorsCompany from "../../constants/ambassadorsCompany"

const Ambassadors = () => {
  return (
    <section className={styles.partners}>
      <Title title="Botschafter" subtitle="& Mentoren" />
      <div className={styles.center}>
        {ambassadorsCompany.map((item) => {
          return item.people.map((subitem, index) => {
            return (
              <article key={index} className={styles.partner}>
                {item.url != "" 

                ? 
                <a href={item.url} target="_blank" rel="noopener noreferrer">
                  <img
                    className={styles.partnerLogo}
                    src={item.src}
                    alt={item.alt}
                  />
                </a>

              :
              <div  className={styles.noPartnerPhoto}></div>          
              }
                <img
                  className={styles.partnerPhoto}
                  src={subitem.src}
                  alt={subitem.name}
                />
                <h3>{subitem.name}</h3>
                <h4>{subitem.role}</h4>
              </article>
            )
          })
        })}
      </div>
    </section>
  )
}

export default Ambassadors
