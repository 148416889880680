import React from "react"
import Title from "../title"
import styles from "../../css/text.module.css"

const AboutUs = () => {
  return (
    <section className={styles.text}>
      <Title title="Über" subtitle="Uns" />
      <div className={styles.center}>
        <article>
          <ul>
            <li>
              #VsCovidUnited ist eine Gemeinschaftsinitiative von führenden
              Unternehmen und Unternehmerpersönlichkeiten
            </li>
            <li>
              #VsCovidUnited ist eine Online-Plattform, die durch das gezielte
              Zusammenbringen von Angebot und Nachfrage einen Beitrag leisten
              will, die Wirtschaft und die Marktteilnehmer besser durch die
              Corona-Krise zu bringen
            </li>
            <li>
              Grundprinzip ist die kurzfristige kostenlose Hilfe auf Basis
              unserer gemeinsamen gesellschaftlichen Verantwortung
            </li>
            <li>
              Darüber hinaus und gerade deshalb wird die Gemeinschaftsinitiative
              versuchen, über verschiedene Maßnahmen liquide Mittel zu erwirken,
              die den Leistungsanbietern und Leistungsempfängern über unsere
              Plattform zugutekommen und damit zum wirtschaftlichen Überleben
              beitragen. Dazu gehören:
            </li>
            <ul>
              <li>Aktive Suche nach Nachfragern mit Projektbudgets</li>
              <li>
                Aufbau eines private equity basierten Hilfsfonds, der gegen eine
                jederzeit abwickelbare Minderheitsbeteiligung an
                hilfebedürftigen Unternehmen die entsprechenden
                Projektleistungen für diese bezahlt
              </li>
              <li>Erwirkung von Förderung mit öffentlichen Mitteln</li>
              <li>Sammeln von Sponsorengeldern</li>
            </ul>
          </ul>
        </article>
      </div>
    </section>
  )
}

export default AboutUs
