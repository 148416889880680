export default [

  {
    src: "",
    alt: "",
    url: "",
    people: [
      {
        src: "/philipp_ostermeier.jpg",
        name: "Philipp Ostermeier",
        role: "Unternehmer, Transforming Corporate Germany",
      },
    ],
  },
  {
    src: "",
    alt: "",
    url: "",
    people: [
      {
        src: "/arnd_thorn.jpg",
        name: "Arnd Thorn",
        role: "Unternehmer und Investor",
      },
    ],
  },
  {
    src: "/business_creators.png",
    alt: "Business Creators Logo",
    url: "https://businesscreators.org/",
    people: [
      {
        src: "/christian_mohr.jpg",
        name: "Christian Mohr",
        role: "Managing Partner",
      },
    ],
  },
  {
    src: "/future_story.png",
    alt: "FutureStory Logo",
    url: "https://www.futurestory.de/",
    people: [
      {
        src: "/nikolaus_roettger.jpg",
        name: "Nikolaus Röttger",
        role: "Founder & Partner",
      },
    ],
  },
  {
    src: "/innovation_for_now.png",
    alt: "Innovation for now Logo",
    url: "https://innovationfornow.de/",
    people: [
      {
        src: "/harald_neidhardt.jpg",
        name: "Harald Neidhardt",
        role: "CEO",
      },
    ],
  },
  {
    src: "/mission_female.png",
    alt: "Mission Female Logo",
    url: "https://www.missionfemale.com/#mission",
    people: [
      {
        src: "/frederike_probert.jpg",
        name: "Frederike Probert",
        role: "Gründerin & Geschäftsführerin",
      },
    ],
  },
  {
    src: "/adobe.png",
    alt: "Adobe Logo",
    url: "https://www.adobe.com/de/",
    people: [
      {
        src: "/hartmut_koenig.jpg",
        name: "Hartmut König",
        role: "CTO Central Europe",
      },
    ],
  },
  {
    src: "/how_to_okr.png",
    alt: "how-to-okr.com Logo",
    url: "https://www.how-to-okr.com/",
    people: [
      {
        src: "/hannes_albrecht.jpg",
        name: "Hannes Albrecht",
        role: "Founder how-to-okr.com",
      },
    ],
  },
  {
    src: "/Wipro Logo.gif",
    alt: "Wipro Logo.gif",
    url: "https://www.wipro.com/",
    people: [
      {
        src: "/MS 1.jpg",
        name: "Michael Seiger",
        role: "Country Head & Managing Director Germany",
      },
    ],
  },
  {
    src: "/Wipro Logo.gif",
    alt: "Wipro Logo.gif",
    url: "https://www.wipro.com/",
    people: [
      {
        src: "/Sujan Kotian_Foto.jpg",
        name: "Sujan Kotian",
        role: "Head of Strategic Engagements, PE & Advisor Relations D-A-CH",
      },
    ],
  }
]
