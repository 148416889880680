import React from "react"
import Title from "../title"
import styles from "../../css/partners.module.css"
import initiatives from "../../constants/initiatives"

const Initiatives = () => {
  return (
    <section className={`${styles.partners} ${styles.grey}`}>
      <Title title="Partner" subtitle="Initiativen" />
      <div className={styles.center}>
        {initiatives.map((item, index) => {
          return (
            <article key={index} className={styles.partner}>
              <a href={item.url} target="_blank" rel="noopener noreferrer">
                <img
                  className={styles.partnerLogo}
                  src={item.src}
                  alt={item.alt}
                />
              </a>
            </article>
          )
        })}
      </div>
    </section>
  )
}

export default Initiatives
